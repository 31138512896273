(function () {
    "use strict";

    angular.module("papp").filter("maxPTime", maxPTime);

    function maxPTime() {
        return maxPTimeFilter;

        function maxPTimeFilter(maxPTime) {
            var maxPTimeDict = {
                null: "Ubegrenset",
                15: "15 minutter",
                30: "0,5 time",
                60: "1 time",
                120: "2 timer",
                180: "3 timer",
                240: "4 timer",
                480: "8 timer",
                660: "11 timer",
                1440: "1 døgn",
                2880: "2 døgn",
                10080: "1 uke",
                40320: "4 uker",
            };
            return maxPTimeDict[maxPTime];
        }
    }
})();
