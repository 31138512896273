(function () {
    "use strict";

    angular.module("papp").factory("ParkingUtils", ParkingUtils);

    function ParkingUtils() {
        var service = {
            convertPMaksTid: convertPMaksTid,
        };

        return service;

        function convertPMaksTid(pMaksTidString) {
            switch (pMaksTidString) {
                case "Ubegrenset":
                    return null;
                case "0,25 timer":
                case "15 minutter":
                case "1 kvarter":
                    return 15;
                case "0,5 timer":
                    return 30;
                case "1 time":
                    return 60;
                case "2 timer":
                    return 120;
                case "3 timer":
                    return 180;
                case "4 timer":
                    return 240;
                case "8 timer":
                    return 480;
                case "11 timer":
                    return 660;
                case "24 timer":
                case "1 døgn":
                    return 1440;
                case "48 timer":
                case "2 døgn":
                    return 2880;
                case "1 uke":
                    return 10080;
                case "4 uker":
                    return 40320;
                default:
                    return null;
            }
        }
    }
})();
